import React from 'react';
import { toast } from 'react-toastify';

import { Colors } from '../../../styles/colors';
import { Urls } from '../../urls';
import { useJobContext } from '../Context';
import { validateSubmitedValues } from '../../../utils/form';
import { jobOfferFormProperties } from '../../../types/jobOffer';

import { useRouter } from '../../../hooks/useRouter';

import { GET_JOB_POSTS } from '../../../graph/queries/getJobPosts';
import { EntityType, SortDirection } from '../../../generated/types';

import { H2 } from '../../../components/atoms/Typography/Headings';
import { WrapperButtons, SecondaryButton, PrimaryButton } from '../../../components/atoms/Button/Buttons';
import { NavigateNextIcon } from '../../../components/atoms/Icons';
import { AgencyDetails } from '../../../components/molecules/AgencyDetails';
import { CompanyDetails } from '../../../components/molecules/CompanyDetails';
import { RateDetails } from '../../../components/molecules/RateDetails';
import { JobDetails } from '../../../components/molecules/JobDetails';
import { HRSection } from '../../../components/atoms/HRSection';
import { companyValidationSchema } from './Company';
import { agencyValidationSchema } from './Agency';
import { jobValidationSchema } from './Job';
import { GET_JOB_POST } from '../../../graph/queries/getJobPost';

export const ConfirmOffer: React.FC<{}> = () => {
  const { query: { userUuid, jobUuid } = {}, navigate, routerState } = useRouter();
  const { state, dispatch, updateJob, archiveFile, createContact } = useJobContext();
  const { formValues, files, contacts } = state;

  if (!userUuid) {
    return null;
  }

  if (!jobUuid) {
    return null;
  }

  async function handleClick(event: React.MouseEvent<HTMLElement>) {
    event.preventDefault();

    if (!userUuid || !jobUuid) {
      return null;
    }

    const errorsTabs = [];
    const isValidCompany = companyValidationSchema.isValidSync(formValues);
    if (!isValidCompany) {
      errorsTabs.push('company');
      toast.error('Please fill in all required fields in the company section');
    }
    const isValidAgency = agencyValidationSchema.isValidSync(formValues);
    if (!isValidAgency) {
      errorsTabs.push('agency');
      toast.error('Please fill in all required fields in the agency section');
    }

    const isValidJob = jobValidationSchema.isValidSync(formValues);
    if (!isValidJob) {
      errorsTabs.push('job');
      toast.error('Please fill in all required fields in the job section');
    }

    dispatch({
      type: 'SET_ERRORS_TABS',
      payload: errorsTabs,
    });

    if (errorsTabs.length > 0) {
      return null;
    }

    const formattedFormValues = validateSubmitedValues(formValues, jobOfferFormProperties);

    updateJob({
      variables: {
        userUuid,
        jobUuid,
        data: formattedFormValues,
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GET_JOB_POSTS,
          variables: {
            where: {
              userUuid,
              isDeleted: false,
            },
            sort: { direction: SortDirection.Desc, field: 'createdAt' },
          },
        },
        {
          query: GET_JOB_POST,
          variables: {
            userUuid,
            jobUuid,
          },
        },
      ],
    });

    for (const file of files) {
      await archiveFile({
        variables: {
          entityType: EntityType.Jobpost,
          userUuid,
          jobUuid,
          fileUuid: file.uuid,
          isDeleted: false,
        },
      });
    }

    for (const contact of contacts) {
      if (contact?.uuid) {
        continue;
      }
      await createContact({
        variables: {
          entityType: EntityType.Jobpost,
          userUuid,
          jobUuid,
          data: contact,
        },
      });
    }

    if (routerState?.from) {
      navigate(routerState.from);
    } else {
      navigate(`/${Urls.JobPosts}#your_job_posts`);
    }
  }

  const child = (
    <>
      <AgencyDetails
        isThroughAgency={formValues.isThroughAgency}
        agencyName={formValues.agencyName}
        agentName={formValues.agentName}
        agentEmail={formValues.agentEmail}
        agentPhone={formValues.agentPhone}
        referralFee={formValues.referralFee}
      />
      <HRSection />
      <CompanyDetails
        company={formValues.company}
        companyInfo={formValues.companyInfo}
        companyLocation={formValues.companyLocation}
        companyLocationCity={formValues.companyLocationCity}
        companyLocationCountry={formValues.companyLocationCountry}
        companyLocationLatitude={formValues.companyLocationLatitude}
        companyLocationLongitude={formValues.companyLocationLongitude}
        companyLocationPostCode={formValues.companyLocationPostCode}
        companyLocationStreet={formValues.companyLocationStreet}
        companyLocationUrl={formValues.companyLocationUrl}
        companyWebsite={formValues.companyWebsite}
      />
      <HRSection />
      <JobDetails
        jobSeniorityLevel={formValues.jobSeniorityLevel}
        jobTitle={formValues.jobTitle}
        jobDescription={formValues.jobDescription}
        jobRequirement={formValues.jobRequirement}
        jobUrl={formValues.jobUrl}
      />
      <HRSection />
      <RateDetails
        duration={formValues.duration}
        remoteOption={formValues.remoteOption}
        employmentType={formValues.employmentType}
        ir35={formValues.ir35}
        rate={formValues.rate}
      />
      <WrapperButtons>
        <SecondaryButton
          inline
          onClick={() => {
            if (routerState?.from) {
              navigate(routerState.from);
            } else {
              navigate(`/${Urls.Dashboard}`);
            }
          }}
        >
          Cancel
        </SecondaryButton>
        <PrimaryButton inline type="submit" onClick={handleClick} iconRight={<NavigateNextIcon color={Colors.White} />}>
          Confirm
        </PrimaryButton>
      </WrapperButtons>
    </>
  );

  return (
    <>
      <H2>Confirm</H2>
      {child}
    </>
  );
};

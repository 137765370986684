import { useEffect } from 'react';
import styled from 'styled-components';

import { usePersistReducer, initialState } from '../../Job/Store';
import { useRouter } from '../../../hooks/useRouter';
import { formatUrl } from '../../../utils/url';
import { jobContext } from '../../Job/Context';
import { validateInitialValues } from '../../../utils/form';
import { Urls } from '../../urls';
import { jobOfferFormProperties } from '../../../types/jobOffer';

import { useArchiveFile } from '../../../graph/mutations/archiveFile';
import { useCreateContact } from '../../../graph/mutations/createContact';
import { useUpdateJobPost } from '../../../graph/mutations/updateJobPost';
import { useArchiveContact } from '../../../graph/mutations/archiveContact';
import { useGetJobPost } from '../../../graph/queries/getJobPost';

import { Sidebar, SidebarLayout, SidebarContent } from '../../../components/atoms/Layout/Layout';
import { VerticalNav, VerticalNavItem, VerticalNavStatus } from '../../../components/molecules/VerticalNav';
import {
  SuitcaseIcon,
  MoneyBillIcon,
  OfficeBuildingIcon,
  PeopleFullIcon,
  StarIcon,
} from '../../../components/atoms/Icons';
import { Agency } from '../../Job/Common/Agency';
import { Company } from '../../Job/Common/Company';
import { Job } from '../../Job/Common/Job';
import { Rate } from '../../Job/Common/Rate';
import { ConfirmOffer } from '../../Job/Common/ConfirmPost';
import { InfoAlert } from '../../../components/atoms/InfoAlert';
import { JobPostStatus } from '../../../generated/types';

const LOCAL_STORAGE_KEY = 'jobpost';

export const Update = () => {
  const {
    navigate,
    location: { pathname },
    query: { userUuid = '', jobUuid = '' },
  } = useRouter();
  const [state, dispatch] = usePersistReducer(LOCAL_STORAGE_KEY, initialState);
  const [updateJobPost, updateJobPostState] = useUpdateJobPost();
  const [archiveFile, archiveFileState] = useArchiveFile();
  const [createContact, createContactState] = useCreateContact();
  const [archiveContact, archiveContactState] = useArchiveContact();

  const { data: { jobPost } = {} } = useGetJobPost({
    variables: { userUuid, jobUuid, includeDeleted: true },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (jobPost) {
      if (jobPost.updatedAt) {
        dispatch({
          type: 'CHANGE_MAX_VALID_STEP',
          payload: 5,
        });
      }

      dispatch({
        type: 'INITIALIZE_FORM_VALUES',
        payload: validateInitialValues(jobPost, jobOfferFormProperties),
      });

      dispatch({
        type: 'INITIALIZE_SAVED_FILES',
        payload: jobPost.files,
      });

      dispatch({
        type: 'INITIALIZE_SAVED_CONTACTS',
        payload: jobPost.contacts,
      });
    }
  }, [jobPost]);

  useEffect(() => {
    const step = +pathname.substring(pathname.length - 1) || 1;
    dispatch({
      type: 'CHANGE_STEP',
      payload: step,
    });
  }, [pathname]);

  if (!userUuid) {
    return null;
  }

  const context = {
    title: 'Update job post',
    state,
    dispatch,
    updateJob: updateJobPost,
    updateJobState: updateJobPostState,
    archiveFile,
    archiveFileState,
    createContact,
    createContactState,
    archiveContact,
    archiveContactState,
  };

  return (
    <SidebarLayout>
      <jobContext.Provider value={context}>
        <Sidebar>
          <VerticalNav>
            <VerticalNavItem
              active={state.currentstep === 1}
              enabled={state.maxValidStep > 0}
              onClick={() => {
                if (state.maxValidStep > 0) {
                  navigate(formatUrl(`${Urls.JobOfferUpdateHome}/${Urls.JobOfferUpdateStep1}`, { userUuid, jobUuid }));
                }
              }}
            >
              <VerticalNavStatus
                active={state.currentstep === 1}
                enabled={state.maxValidStep > 0}
                icon={<PeopleFullIcon />}
              />
              Agency
            </VerticalNavItem>

            <VerticalNavItem
              active={state.currentstep === 2}
              enabled={state.maxValidStep > 1}
              onClick={() => {
                if (state.maxValidStep > 1) {
                  navigate(formatUrl(`${Urls.JobOfferUpdateHome}/${Urls.JobOfferUpdateStep2}`, { userUuid, jobUuid }));
                }
              }}
            >
              <VerticalNavStatus
                active={state.currentstep === 2}
                enabled={state.maxValidStep > 1}
                icon={<OfficeBuildingIcon />}
              />
              Company
            </VerticalNavItem>

            <VerticalNavItem
              active={state.currentstep === 3}
              enabled={state.maxValidStep > 2}
              onClick={() => {
                if (state.maxValidStep > 2) {
                  navigate(formatUrl(`${Urls.JobOfferUpdateHome}/${Urls.JobOfferUpdateStep3}`, { userUuid, jobUuid }));
                }
              }}
            >
              <VerticalNavStatus
                active={state.currentstep === 3}
                enabled={state.maxValidStep > 2}
                icon={<SuitcaseIcon />}
              />
              Job
            </VerticalNavItem>

            <VerticalNavItem
              active={state.currentstep === 4}
              enabled={state.maxValidStep > 3}
              onClick={() => {
                if (state.maxValidStep > 3) {
                  navigate(formatUrl(`${Urls.JobOfferUpdateHome}/${Urls.JobOfferUpdateStep4}`, { userUuid, jobUuid }));
                }
              }}
            >
              <VerticalNavStatus
                active={state.currentstep === 4}
                enabled={state.maxValidStep > 3}
                icon={<MoneyBillIcon />}
              />
              Rate
            </VerticalNavItem>

            <VerticalNavItem
              active={state.currentstep === 5}
              enabled={state.maxValidStep > 4}
              onClick={() => {
                if (state.maxValidStep > 4) {
                  navigate(formatUrl(`${Urls.JobOfferUpdateHome}/${Urls.JobOfferUpdateStep5}`, { userUuid, jobUuid }));
                }
              }}
            >
              <VerticalNavStatus
                active={state.currentstep === 5}
                enabled={state.maxValidStep > 4}
                icon={<StarIcon />}
              />
              Confirm
            </VerticalNavItem>
          </VerticalNav>
        </Sidebar>
        <SidebarContent>
          {jobPost?.status === JobPostStatus.Published && (
            <AlertWrapper>
              <InfoAlert>
                <p>
                  <strong>Important:</strong> You can update your job post as many times as you want until you find the
                  perfect candidate. But once you update your job post, it will be unpublished and you will have to
                  publish it again. Then a moderator will have to approve it before it is published.
                </p>
              </InfoAlert>
            </AlertWrapper>
          )}

          {state.currentstep === 1 && <Agency />}
          {state.currentstep === 2 && <Company />}
          {state.currentstep === 3 && <Job />}
          {state.currentstep === 4 && <Rate />}
          {state.currentstep === 5 && <ConfirmOffer />}
          {/* <pre>{JSON.stringify(state, null, 2)}</pre> */}
          {/* <pre>{JSON.stringify(jobPost, null, 2)}</pre> */}
        </SidebarContent>
      </jobContext.Provider>
    </SidebarLayout>
  );
};

const AlertWrapper = styled.div`
  max-width: 900px;
  padding: 16px 0;
`;

import { gql, useMutation, MutationHookOptions } from '@apollo/client';

import { Mutation, MutationUpdateJobPostArgs } from '../../generated/types';

export const UPDATE_JOB_POST = gql`
  mutation UpdateJobPost($userUuid: String!, $jobUuid: String!, $data: JobPostInputData!) {
    updateJobPost(userUuid: $userUuid, jobUuid: $jobUuid, data: $data) {
      uuid
      userUuid

      status

      isThroughAgency
      agencyName
      agentName
      agentEmail
      agentPhone
      referralFee

      company
      companyInfo
      companyWebsite
      companyLocation
      companyLocationCity
      companyLocationCountry
      companyLocationLatitude
      companyLocationLongitude
      companyLocationPostCode
      companyLocationStreet
      companyLocationUrl

      jobTitle
      jobSeniorityLevel
      jobDescription
      jobRequirement
      jobUrl

      ir35
      duration
      rate
      employmentType
      remoteOption

      files {
        uuid
        filename
        size
        mimetype
        formattedMimetype
        resource
        createdAt
        updatedAt
        isDeleted
        temporaryUrl
      }

      contacts {
        uuid
        fullName
        position
        email
        phoneNumber
        linkedin
      }

      createdBy {
        uuid
        email
        nickname
        name
      }

      updatedBy {
        uuid
        email
        nickname
        name
      }

      aiRequiredSkills
      aiSoftSkills
      aiNiceToHaveSkills

      createdAt
      updatedAt

      permissions

      isDeleted
    }
  }
`;

export const useUpdateJobPost = (
  options?: MutationHookOptions<Pick<Mutation, 'updateJobPost'>, MutationUpdateJobPostArgs>
) =>
  useMutation<Pick<Mutation, 'updateJobPost'>, MutationUpdateJobPostArgs>(UPDATE_JOB_POST, {
    awaitRefetchQueries: false,
    refetchQueries: [],
    ...options,
  });
